import {isEnded} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import React, {useRef, useState} from 'react'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import classesV2 from '../../../classes-v2.scss'
import {Countdown} from '../../../countdown'
import {EventImage} from '../../../event-image'
import {FullDateLocation} from '../../../full-date-location'
import {LinkToPage} from '../../../link-to-page'
import {Members} from '../../../members'
import {Ribbon} from '../../../ribbon'
import {RsvpButton} from '../../../rsvp-button'
import {ShortDateLocation} from '../../../short-date-location'
import {SocialBar} from '../../../social-bar'
import {PADDING_RATIO} from './constants'
import {DetailsStyles, SideBySideItemProps} from './interfaces'
import s from './side-by-side-item.scss'

const initialDetailsStyles: DetailsStyles = {
  contentWrapper: {},
  scrollableContainer: {},
}

export const SideBySideItem = ({event, evenItem, showMembers, membersEnabled, t, showRibbon}: SideBySideItemProps) => {
  const {
    allBreakpoints: {isListImageEnabled, isListCountdownEnabled},
  } = useVisibilityStyles()
  const imageVisible = isListImageEnabled()

  const getContainerClasses = () => {
    return classNames(s.container, {
      [s.even]: evenItem,
      [s.odd]: !evenItem,
      [s.noDescription]: !event.description,
    })
  }

  const renderShortDateAndLocation = () => {
    return (
      <div className={classNames(s.shortDateLocation, s.cardContent)}>
        <ShortDateLocation
          event={event}
          locationClassName={classesV2.eventLocation}
          dateClassName={classesV2.eventDate}
        />
      </div>
    )
  }

  const renderFullDateAndLocation = () => {
    return (
      <div className={classNames(s.fullDateLocation, s.cardContent)}>
        <FullDateLocation
          event={event}
          locationClassName={classesV2.eventLocation}
          dateClassName={classesV2.eventDate}
        />
      </div>
    )
  }

  const scrollableContainerRef = useRef<HTMLDivElement>(null)
  const [detailsStyles, setDetailsStyles] = useState<DetailsStyles>(initialDetailsStyles)

  const handleMouseOver = () => {
    const scrollableContainerWidth = scrollableContainerRef.current.getBoundingClientRect().width
    const padding = scrollableContainerWidth * PADDING_RATIO
    const detailsWidth = scrollableContainerWidth - padding
    setDetailsStyles({
      contentWrapper: {
        width: detailsWidth,
        padding: 0,
        paddingInlineStart: padding,
      },
      scrollableContainer: {
        overflowY: 'auto',
      },
    })
  }

  const handleMouseOut = () => {
    setDetailsStyles(initialDetailsStyles)
  }

  return (
    <div className={getContainerClasses()} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {imageVisible ? (
        <div className={s.imageContainer}>
          <EventImage event={event} opacityFallback backgroundFallback className={s.imageZoom} />
        </div>
      ) : null}
      <div className={s.content}>
        {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
        {isListCountdownEnabled() && !isEnded(event) && (
          <div className={s.countdown}>
            <Countdown event={event} />
          </div>
        )}
        <div className={s.detailsWrapper} ref={scrollableContainerRef} style={detailsStyles.scrollableContainer}>
          <div className={s.details} style={detailsStyles.contentWrapper}>
            {renderShortDateAndLocation()}
            <div className={s.title} data-hook="title">
              <LinkToPage allowExternal event={event}>
                {event.title}
              </LinkToPage>
            </div>
            <div className={s.eventInfoContainer}>
              {renderFullDateAndLocation()}
              {!!event.description && (
                <div className={s.descriptionContainer}>
                  <div className={s.description} data-hook="description">
                    {event.description}
                  </div>
                </div>
              )}
            </div>
            {!membersEnabled && (
              <div className={classNames(s.socialBar, s.cardContent)}>
                <SocialBar t={t} event={event} />
              </div>
            )}
          </div>
        </div>
        {showMembers ? (
          <div className={s.members} data-hook="members">
            <Members event={event} />
          </div>
        ) : null}
        <RsvpButton event={event} containerClassName={s.button} />
        {membersEnabled && (
          <div className={classNames(s.socialBar, s.bellowContent, s.cardContent)}>
            <SocialBar t={t} event={event} />
          </div>
        )}
      </div>
    </div>
  )
}
