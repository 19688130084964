import {useSettings} from '@wix/tpa-settings/react'
import React from 'react'
import settingsParams from '../../../settingsParams'
import {FeaturedSideBySide} from './featured-side-by-side'
import {SideBySideList} from './side-by-side-list'

export const SideBySide = () => {
  const {get} = useSettings()
  const featuredLayout = get(settingsParams.featuredEvent)

  return featuredLayout ? <FeaturedSideBySide /> : <SideBySideList />
}
